import { Action, Reducer } from 'redux';
 
// -----------------
// STATE - Defines the structure of the OrderListing state.
 
export interface OrderListingState {
    selectedBranches: string[];
    selectedShippings: string[];
    selectedStatus: string[];
    selectedCustomers: string[];
    selectedWarhouse: string[];
    searchCustomerId: any;
    internalCustomerId: any;
    fromDate: string;
    toDate: string;
}
 
// Initial state
const initialState: OrderListingState = {
    selectedBranches: [],
    selectedShippings: [],
    selectedStatus: [],
    selectedCustomers: [],
    selectedWarhouse: [],
    searchCustomerId: {},
    fromDate: '',
    toDate: '',
    internalCustomerId: {},
};
 
// -----------------
// ACTIONS - Define distinct action types and structures for each state property.
 
export interface SetSelectedBranchesAction {
    type: 'SET_SELECTED_BRANCHES';
    payload: string[];
}
 
export interface SetSelectedShippingsAction {
    type: 'SET_SELECTED_SHIPPINGS';
    payload: string[];
}
 
export interface SetSelectedStatusAction {
    type: 'SET_SELECTED_STATUS';
    payload: string[];
}
 
export interface SetSelectedCustomersAction {
    type: 'SET_SELECTED_CUSTOMERS';
    payload: string[];
}
 
export interface SetSelectedWarehouseAction {
    type: 'SET_SELECTED_WAREHOUSE';
    payload: string[];
}
 
export interface SetSearchCustomerIdAction {
    type: 'SET_SEARCH_CUSTOMER_ID';
    payload: any;
}
 
export interface SetFromDateAction {
    type: 'SET_FROM_DATE';
    payload: string;
}
 
export interface SetToDateAction {
    type: 'SET_TO_DATE';
    payload: string;
}
 
export interface SetInternalCustomerIdAction {
    type: 'SET_INTERNAL_CUSTOMER_ID';
    payload: any;
}
 
// Declare the KnownAction type to include all action interfaces.
export type KnownAction =
    | SetSelectedBranchesAction
    | SetSelectedShippingsAction
    | SetSelectedStatusAction
    | SetSelectedCustomersAction
    | SetSelectedWarehouseAction
    | SetSearchCustomerIdAction
    | SetFromDateAction
    | SetToDateAction
    | SetInternalCustomerIdAction;
 
// -----------------
// ACTION CREATORS - Create functions to dispatch actions for each state property.
 
export const actionCreators = {
    setSelectedBranches: (branches: string[]) => ({
        type: 'SET_SELECTED_BRANCHES',
        payload: branches
    } as SetSelectedBranchesAction),
 
    setSelectedShippings: (shippings: string[]) => ({
        type: 'SET_SELECTED_SHIPPINGS',
        payload: shippings
    } as SetSelectedShippingsAction),
 
    setSelectedStatus: (status: string[]) => ({
        type: 'SET_SELECTED_STATUS',
        payload: status
    } as SetSelectedStatusAction),
 
    setSelectedCustomers: (customers: string[]) => ({
        type: 'SET_SELECTED_CUSTOMERS',
        payload: customers
    } as SetSelectedCustomersAction),
 
    setSelectedWarehouse: (warehouse: string[]) => ({
        type: 'SET_SELECTED_WAREHOUSE',
        payload: warehouse
    } as SetSelectedWarehouseAction),
 
    setSearchCustomerId: (customerId: any) => ({
        type: 'SET_SEARCH_CUSTOMER_ID',
        payload: customerId
    } as SetSearchCustomerIdAction),
 
    setFromDate: (fromDate: string) => ({
        type: 'SET_FROM_DATE',
        payload: fromDate
    } as SetFromDateAction),
 
    setToDate: (toDate: string) => ({
        type: 'SET_TO_DATE',
        payload: toDate
    } as SetToDateAction),
    setInternalCustomerId: (internalCustomerId: any) => ({
        type: 'SET_INTERNAL_CUSTOMER_ID',
        payload: internalCustomerId
    } as SetInternalCustomerIdAction)
};
 
// -----------------
// REDUCER - Handles state updates based on action type.
 
export const OrderListingReducer: Reducer<OrderListingState> = (
    state: OrderListingState | undefined = initialState,
    incomingAction: Action
): OrderListingState => {
    const action = incomingAction as KnownAction;
 
    switch (action.type) {
        case 'SET_SELECTED_BRANCHES':
            return { ...state, selectedBranches: action.payload };
 
        case 'SET_SELECTED_SHIPPINGS':
            return { ...state, selectedShippings: action.payload };
 
        case 'SET_SELECTED_STATUS':
            return { ...state, selectedStatus: action.payload };
 
        case 'SET_SELECTED_CUSTOMERS':
            return { ...state, selectedCustomers: action.payload };
 
        case 'SET_SELECTED_WAREHOUSE':
            return { ...state, selectedWarhouse: action.payload };
 
        case 'SET_SEARCH_CUSTOMER_ID':
            return {...state, searchCustomerId: action.payload };
 
        case 'SET_FROM_DATE':
            return {...state, fromDate: action.payload };
        case 'SET_TO_DATE':
            return {...state, toDate: action.payload };
 
 
        case 'SET_INTERNAL_CUSTOMER_ID':
            return {...state, internalCustomerId: action.payload };
        default:
            return state || initialState;
    }
};