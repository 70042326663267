import React, { useEffect, useState, useRef } from "react";
import Item from "./sample.jpg";
const set = require('lodash/set');

import toast from "react-hot-toast";
import { baseUrl, SubscriptionKey } from "../config";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Button from "@material-ui/core/Button";
import { useHistory, useParams  } from "react-router-dom";
import Popover from "@material-ui/core/Popover";
import CircularProgress from "@material-ui/core/CircularProgress";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import InfoIcon from "@material-ui/icons/Info";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MyAttributes from "./attributes";
import TextField from "@material-ui/core/TextField";
import Globals from "../../../dataservices/global";
import { GetShippingMethods } from "../../../dataservices/action/users/getUserApiSettins";
import { shippingMethodModel } from "../../../dataservices/model/shippingmethods";
import { GetmpLocationsANDagilityBranch, SaveEditedOrder, GetShippingMethodsByAccountNo } from "../../../dataservices/action/marketplace/marketplace";
import { channellist } from "../../../dataservices/model/channellist";
import { orderEditObj } from "../../../dataservices/model/orderEdit";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import "./style.css";
import { error } from "console";
function OrderDetails() {

    const history = useHistory();
    const { OLT }: any = useParams();
    const [olisturl, setolisturl] = useState<any>("orderlisting");
    const refHistory = useRef<any>(null);
    const [trackNumberArray, setTracknumberArray] = useState<any>([]);
    const [itemSubTotal, setItemSubTotal] = useState<any>(0);
    const [loading, setLoading] = useState(true);
    const [orderShipments, setOrderShipments] = useState<any>([]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );
    const [attributes, setAttribute] = useState<any>({});

    const [orderHistory, setOrderHistory] = useState<any>([]);
    const [isEditing, setIsEditing] = useState(false);
    var shippingMethod: shippingMethodModel[] = new Array<shippingMethodModel>();

    const [shippingData, setShippingData] = useState(shippingMethod);

    var shippingMethod: shippingMethodModel[] = new Array<shippingMethodModel>();

    const [shippingMethods, setshippingMethods] = useState(shippingMethod);

    var intlocationlist: channellist[] = new Array<channellist>();
    const [loclist, setloclist] = useState(intlocationlist);
    const [warehouseList, setWarehouseList] = useState([
        { value: "", title: "" },
    ]);
    const [accNo, setAccNo] = useState("");
    const [currentIndex, setCurrentIndex] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [releaseOrderData, setReleaseOrderData] = useState({})
    const [isOnHoldOrder, setIsOnHoldOrder] = useState(false);
    const [status, setStatusList] = useState([{ value: "", title: "" }]);

    const handleClickEditMode = (event: any, list: any, index: any) => {
        console.log("list....", list)
        setAttribute(list);
        setAnchorEl(event.currentTarget);
        setCurrentIndex(index);
        console.log("list....", index)
    };
    const handleClick = (event: any, list: any) => {
        setAttribute(list);
        setAnchorEl(event.currentTarget);
        //setCurrentIndex(index);
        //console.log("list....",index)
    };
    const handleGoBack = () => {
        history.goBack();
      };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [data, setData] = useState<any>({
        accountNumber: "",
        statusCode: "",
        customerOrderId: "",
        customerOrderDateTime: "",
        transactionId: "",
        transactionDate: "",
        agilityOrderId: null,
        customerCode: null,
        notes: "",
        sourceSystem: "",
        customerWarehouseId: "",
        branchId: null,
        shipToSequenceNumber: null,
        shipVia: null,
        shipTo: {
            name: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            shipToPhone: "",
        },
        billTo: {
            company: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
            shipToPhone: "",
        },
        orderDetails: {
            customerOrderId: "",
            customerOrderDateTime: "",
            orderType: "",
            shippingMethod: "",
            shipComplete: "",
            expectedDeliveryDate: "",
            expectedDeliveryTime: null,
            routeId: "",
            agilityShipVia: null,
            jobNumber: null,
        },
        orderLineItems: [],
        customerContactInformation: {
            additionalContactEmails: ["", ""],
            customerContactName: "",
            customerContactEmail: "",
            customerContactPhone: "",
            customerContactAddress: {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                state: "",
                zipCode: "",
                country: "",
                phone: "",
            },
        },
        orderHistory: [],
        orderShipments: [],
    });
    function formatDate(date: any) {
        var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
    }


    useEffect(() => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("Authorization")}`,
        };
        let orderwarehouse = "";
        if (OLT == "F") {
            orderwarehouse = "GetWarehouse"

        }
        else {
            orderwarehouse = "GetNFWarehouse"

        }

        axios
            .get(`${baseUrl}/${orderwarehouse}`, { headers })
            .then((response) => {
                let ware: any = [];
                response.data.map((x: any) => {
                    if (ware.find((y: any) => y.title === x.wareHouseId)) {
                    } else {
                        ware.push({
                            title: x.wareHouseId,
                            value: x.wareHouseId,
                        });
                    }
                });
                setWarehouseList(ware);
                //console.log("ware....",ware)
            })
            .catch((error) => {
                console.error("Error:", error);
            });

            axios
            .get(`${baseUrl}/GetOrderSatus`, { headers })
            .then((response) => {
                let status: any = [];
                response.data.map((x: any) => {
                    status.push({
                        title: x.statusDescription,
                        value: x.statusCode,
                    });
                    setStatusList(status);
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, [])
    useEffect(() => {

        const orderItem = JSON.parse(localStorage.getItem("orderItem") || "{}");
        if (orderItem.path === "track") {
            refHistory.current.scrollIntoView();
        }
        let orderdetailsAPIfun = "";

        if (OLT == "F") {
            orderdetailsAPIfun = "GetSalesOrdersData"
            setolisturl("/orderlisting/F")
        }
        else {
            orderdetailsAPIfun = "GetNFSalesOrdersData"
            setolisturl("/orderlisting/D")

        }
        const headers = {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("Authorization")}`,
        };
        axios
            .get(
                `${baseUrl}/${orderdetailsAPIfun}?transactionId=${orderItem.transactionId}`,
                { headers }
            )
            .then((response) => {
                setLoading(false);
                const responseValue = response.data.salesOrder[0];
                const updatedData = {
                    ...responseValue,
                    orderLineItems: responseValue.orderLineItems.map((item: any) => ({
                        ...item,
                        hazmat: item.hazmat === null ? "N" : item.hazmat
                    }))
                };

                setData(updatedData);
                // setData(responseValue);
                setAccNo(response.data.salesOrder[0].accountNumber);
                console.log("response.data.salesOrder[0]----", response.data.salesOrder[0])
                setOrderHistory(response.data.salesOrder[0].orderHistory.reverse());
                let itemSub = 0;
                let orderShipments = response.data.salesOrder[0].orderShipments;
                let orderLineItems = response.data.salesOrder[0].orderLineItems;
                orderLineItems.forEach((x: any) => {
                    itemSub = itemSub + x.salesPrice;
                });
                setItemSubTotal(itemSub);
                const newOrderShipments = orderShipments.map((item: any) => {
                    return {
                        ...item,
                        containers: item.containers.map((child: any) => {
                            trackNumberArray.push(child.trackingNumber);
                            return {
                                ...child,
                                packedItems: child.packedItems.map((packedItem: any) => {
                                    return {
                                        ...packedItem,
                                    };
                                }),
                            };
                        }),
                    };
                });
                setOrderShipments([...newOrderShipments]);

                setTracknumberArray([...trackNumberArray]);

                GetShippingMethodsByAccountNo(response.data.salesOrder[0].accountNumber, function (result: shippingMethodModel[]) {
                    if (result != null) {
                        setshippingMethods(result);

                    }
                    //console.log("shipmethods....", result)
                })
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    }, []);
    useEffect(() => {
        const orderItem = JSON.parse(localStorage.getItem("orderItem") || "{}");
        const headers = {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("Authorization")}`,
        };
        axios
            .get(
                `${baseUrl}/getSalesOrders?transactionId=${orderItem.transactionId}`,
                { headers }
            ).then((response) => {
                console.log("resOfGetSales....", response.data)
                setReleaseOrderData(response.data.salesOrder[0]);
            })
            .catch((error) => {
                console.error("Error:", error);
            });

    }, [])

    function formatAMPM(date: any) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    }
    const dateConverter = (x: any) => {
        var today = new Date(x);
        var month = today.toLocaleString("default", { month: "short" });
        let time = formatAMPM(today);
        let date = `${month} ${today.getDate()}, ${today.getFullYear()} ${time}`;
        return date;
    };

    const handleBillingAddress = (data: any) => {
        if (
            data.billTo.company ||
            data.billTo.addressLine1 ||
            data.billTo.addressLine2 ||
            data.billTo.addressLine3 ||
            data.billTo.city ||
            data.billTo.state ||
            data.billTo.country ||
            data.billTo.zipCode ||
            data.customerContactInformation.customerContactPhone ||
            data.customerContactInformation.customerContactEmail
        ) {
            return false;
        } else {
            return true;
        }
    };

    const handleCustomerAddress = (data: any) => {
        if (
            data.customerContactInformation.customerContactAddress.addressLine1 ||
            data.customerContactInformation.customerContactAddress.addressLine2 ||
            data.customerContactInformation.customerContactAddress.addressLine3 ||
            data.customerContactInformation.customerContactAddress.city ||
            data.customerContactInformation.customerContactAddress.state ||
            data.customerContactInformation.customerContactAddress.country ||
            data.customerContactInformation.customerContactAddress.zipCode ||
            data.customerContactInformation.customerContactPhone ||
            data.customerContactInformation.customerContactEmail
        ) {
            return false;
        } else {
            return true;
        }
    };
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        // Convert "true" and "false" strings to booleans and store the result in a new variable
        // const parsedValue = value === "true" ? true : value === "false" ? false : value;
        let updatedOrder = { ...data };
        // Use lodash.set to update the nested value
        set(updatedOrder, name, value);

        setData(updatedOrder);


    }
    const handleShipMethodChange = (e: any) => {
        console.log("shipme....", e.target.value)
        const selectedMethod = e.target.value;
        setData((prevData: any) => ({
            ...prevData,
            orderDetails: {
                ...prevData.orderDetails,
                shippingMethod: selectedMethod
            }
        }));
    }
    const handleWarehouseChange = (e: any) => {
        const selectedWarehouse = e.target.value;
        setData((prevData: any) => ({
            ...prevData, customerWarehouseId: selectedWarehouse

        }));



    }
    console.log("data.......", data);
    const handleEditedOrderSave = () => {
        setLoading(true);
        const dataObj: orderEditObj = {
            accountNumber: data.accountNumber,
            statusCode: data.statusCode,
            customerOrderId: data.customerOrderId,
            customerOrderDateTime: data.customerOrderDateTime,
            transactionId: data.transactionId,
            transactionDate: data.transactionDate,
            agilityOrderId: data.agilityOrderId,
            customerCode: data.customerCode,
            notes: data.notes,
            sourceSystem: data.sourceSystem,
            customerWarehouseId: data.customerWarehouseId,
            branchCode: data.branchId,
            shipToSequenceNumber: data.shipToSequenceNumber,
            shipVia: data.shipVia,
            shipTo: data.shipTo,
            billTo: data.billTo,
            orderDetails: data.orderDetails,
            orderLineItems: data.orderLineItems,
            customerContactInformation: data.customerContactInformation,
            orderHistory: data.orderHistory,
            orderShipments: data.orderShipments,
            acknowledgementDate: ""
        }
        try {
            console.log("dataObjEditSave.......", dataObj)
            SaveEditedOrder(dataObj, function (result: any) {
                console.log("resultOfSave", result);
                if (result.isSuccess) {
                    toast.success(
                        "Order Saved Successfully",
                    );
                    setLoading(false);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);

                } else {
                    toast.error(
                        "Error",
                    );

                }

            })

        }
        catch (error) {
            console.error("Error saving order:", error);
            toast.error("An error occurred while saving the order.");
            setLoading(false); // Ensure loading state is reset in case of an error
        }


    }
    const [triggerApi, setTriggerApi] = useState(false);
    const [source, setSource] = useState<string>("");
    const handleReleaseHold = (source: string) => {
        const updatedReleaseOrderData = {
            ...releaseOrderData,
            statusCode: source === "release" ? "19" : "17",
        };

        setLoading(true);
        const apiUrl = 'https://dev-int-apiservices.aquaquickship.com/rule/evaluate-rules?code=Ini9GJAPDFUa9WUuaH7prDBGUREAo9gUeokZK9eUyfuiAzFuSz5QbQ%3D%3D';

        const callReleasHoldApi = async (data: any) => {
            try {
                console.log("dataforrelease....", data)

                const response = await axios.post(apiUrl, data);
                if (source == "hold") {
                    toast.success(
                        "Hold Released Successfully",
                        { duration: 4000 }
                    );

                } else {
                    toast.success(
                        "Hold Overrided Successfully",
                        { duration: 4000 }
                    );

                }

                setLoading(false);
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            } catch (error) {
                if (source == "hold") {
                    toast.error(
                        "Error in Release Hold",
                        { duration: 4000 }
                    );

                } else {
                    toast.error(
                        "Error in Override Hold",
                        { duration: 4000 }
                    );
                }

                console.log("Error in release hold:", error);
                setLoading(false);
            }

        }
        // Call the async function
        callReleasHoldApi(updatedReleaseOrderData);
    };

    const DeleteAlert = (indexToRemove: any) => {
        confirmAlert({
            title: 'Delete Item',
            message: 'Are you sure you want to delete this Item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => DeleteLineItem(indexToRemove)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }
    const DeleteLineItem = (indexToRemove: any) => {
        setData((prevData: any) => ({
            ...prevData,
            orderLineItems: prevData.orderLineItems.filter((_: any, index: any) => index !== indexToRemove),
        }));

    }
    const handleInputChangeItemName = (e: any) => {
        // setInputValue(e.target.value); // Update inputValue
        const { name, value } = e.target;

        // Convert "true" and "false" strings to booleans and store the result in a new variable
        const parsedValue = value === "true" ? true : value === "false" ? false : value;
        let updatedOrder = { ...data };
        // Use lodash.set to update the nested value
        set(updatedOrder, name, parsedValue);

        setData(updatedOrder);
    };

    // const handleDownloadCSV = () => {
    //     // Prepare CSV data with headers as columns
    //     const csvData = [
    //       [
    //         "Ordered on",
    //         "Order #",
    //         "Shipping Method",
    //         "Transaction Id",
    //         "Branch Code",
    //         "Customer Warehouse",
    //         "Agility Order Id",
    //         "Agility Ship Via",
    //         "Customer Account Id",
    //         "Source System",
    //         "Ship To Sequence Number"
    //       ], // Header row

    //       [
    //         data.customerOrderDateTime,
    //         data.customerOrderId,
    //         data.orderDetails.shippingMethod,
    //         data.transactionId,
    //         data.branchCode,
    //         data.customerWarehouseId,
    //         data.agilityOrderId,
    //         data.orderDetails.agilityShipVia,
    //         data.accountNumber,
    //         data.sourceSystem,
    //         data.shipToSequenceNumber
    //       ] // Data row
    //     ];

    //     // Convert array of arrays to CSV string
    //     const csvContent = csvData.map(row => row.join(",")).join("\n");

    //     // Create a blob with CSV content
    //     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    //     const url = URL.createObjectURL(blob);

    //     // Create a link to download the CSV file
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = "order_data.csv";
    //     link.style.display = "none";
    //     document.body.appendChild(link);
    //     link.click();

    //     // Clean up
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(url);
    //   };

    const handleDownloadCSV = () => {
        // Function to flatten the object
        const flattenObject = (obj: any, prefix: string = '') => {
            let result: any = [];
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const newKey = prefix ? `${prefix}_${key}` : key;  // Combine prefix for nested objects
                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        result = result.concat(flattenObject(obj[key], newKey));  // Recursively flatten nested objects
                    } else {
                        result.push({ [newKey]: obj[key] });
                    }
                }
            }
            return result;
        };

        // Flatten the entire object
        const flattenedData = flattenObject(data);

        // Extract keys (headers) from the flattened object
        const headers = Object.keys(flattenedData.reduce((acc: any, obj: any) => ({ ...acc, ...obj }), {}));

        // Extract values for the data row
        const values = headers.map(header => {
            const valueObj = flattenedData.find((obj: any) => obj.hasOwnProperty(header));
            return valueObj ? valueObj[header] : '';
        });

        // Combine headers and values into CSV rows
        const csvData = [headers.join(","), values.join(",")];

        // Convert array to CSV string
        const csvContent = csvData.join("\n");

        // Create a Blob from the CSV content
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

        // Create a download link and trigger the download
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = "data.csv";
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };
    const findstatus = (item: any) => {
        if (item!="") {
            let result: any = status.find((x) => x.value === item);
            return result!=undefined? result.title :"";
        }
    };
    return (
        <>
            {loading && (
                <div className="loader_orderlisting">
                    <CircularProgress style={{ color: "#000000" }} />
                </div>
            )}
            <div className="fc-orderlisting">
                {isEditing ? (
                    <>
                        <div className="containerOrderDetails" hidden={loading}>
                            {/* <h2>Editing Page</h2> */}
                            <div className="container-extra-fields">
                                {data.orderDetails.shippingMethod && (
                                    <div className="container-extra-item">
                                        <div className="mb-2">
                                            <label className="form-label"><strong>Shipping Method:</strong></label>
                                            {/* {" " + data.orderDetails.shippingMethod} */}

                                            <select className="form-select form-select-lg"
                                                value={data.orderDetails.shippingMethod} onChange={handleShipMethodChange}>

                                                <option value="" disabled>Select Shipping Method</option>
                                                {/* Add initial value if it's not in shippingData */}

                                                {shippingMethods.map((item, index) => (

                                                    <option key={index} value={item.customerShipMethod}>{item.customerShipMethod}</option>


                                                ))

                                                }
                                            </select>

                                        </div>

                                    </div>
                                )}
                                {data.orderDetails.orderType && (
                                    <div className="container-extra-item">
                                        <div className="mb-2">
                                            <label className="form-label"><strong>Order Type:</strong></label>
                                            {/* {" " + data.orderDetails.orderType} */}
                                            <input type="text"
                                                className="form-control"
                                                name="orderDetails.orderType"
                                                value={data.orderDetails.orderType}
                                                onChange={handleInputChange}

                                            />
                                        </div>
                                    </div>
                                )}
                                {data.orderDetails.shipComplete && (
                                    <div className="container-extra-item">
                                        <div className="mb-2">
                                            <label className="form-label"><strong>Ship Complete:</strong></label>
                                            {/* {" " + data.orderDetails.shipComplete} */}
                                            <input type="text"
                                                className="form-control"
                                                name="orderDetails.shipComplete"
                                                placeholder="Ship Complete"
                                                value={data.orderDetails.shipComplete}
                                                onChange={handleInputChange}

                                            />
                                        </div>
                                    </div>
                                )}
                                {data.orderDetails.expectedDeliveryDate && (
                                    <div className="container-extra-item">
                                        <b>Expected Delivery Date:</b>
                                        {" " + data.orderDetails.expectedDeliveryDate}
                                    </div>
                                )}
                                {data.orderDetails.expectedDeliveryTime && (
                                    <div className="container-extra-item">
                                        <b>Expected Delivery Time:</b>
                                        {" " + data.orderDetails.expectedDeliveryTime}
                                    </div>
                                )}
                                {data.orderDetails.agilityShipVia && (
                                    <div className="container-extra-item">
                                        <div className="mb-2">
                                            <label className="form-label"><strong>Agility Ship Via:</strong></label>
                                            {/* {" " + data.orderDetails.agilityShipVia} */}
                                            <input type="text"
                                                className="form-control"
                                                name="orderDetails.agilityShipVia"
                                                placeholder="Agility Ship Via"
                                                value={data.orderDetails.agilityShipVia}
                                                onChange={handleInputChange}

                                            />
                                        </div>
                                    </div>
                                )}
                                {data.sourceSystem && (
                                    <div className="container-extra-item">
                                        <div className="mb-2">
                                            <label className="form-label"><strong>Source System:</strong></label>
                                            {/* {" " + data.sourceSystem} */}
                                            <input type="text"
                                                className="form-control"
                                                name="sourceSystem"
                                                placeholder="Source System"
                                                value={data.sourceSystem}
                                                onChange={handleInputChange}

                                            />
                                        </div>
                                    </div>
                                )}
                                {data.customerWarehouseId && (
                                    <div className="container-extra-item">
                                        <div className="mb-2">
                                            <label className="form-label"><strong>Customer Warehouse:</strong></label>
                                            {/* {" " + data.customerWarehouseId} */}
                                            <select className="form-select form-select-lg"
                                                value={data.customerWarehouseId} onChange={handleWarehouseChange}>

                                                <option value="" disabled>Select Warehouse</option>

                                                {warehouseList.map((item, index) => (

                                                    <option key={index} value={item.value}>{item.title}</option>


                                                ))

                                                }
                                            </select>

                                        </div>
                                    </div>
                                )}
                                {data.accountNumber && (
                                    <div className="container-extra-item">
                                        <label></label>
                                        <div className="d-flex justify-content-start align-items-center pt-3">
                                            <strong>Customer Account Id: </strong>
                                            <span className="ps-2">{" " + data.accountNumber}</span>
                                        </div>
                                    </div>
                                )}
                                {data.orderDetails.routeId && (
                                    <div className="container-extra-item">
                                        <div className="mb-2">
                                            <label className="form-label"><strong>Route Id:</strong></label>
                                            {/* {" " + data.orderDetails.routeId} */}
                                            <input type="text"
                                                className="form-control"
                                                name="orderDetails.routeId"
                                                placeholder="Route Id"
                                                value={data.orderDetails.routeId}
                                                onChange={handleInputChange}

                                            />
                                        </div>
                                    </div>
                                )}
                                {data.orderDetails.jobNumber && (
                                    <div className="container-extra-item">
                                        <div className="mb-2">
                                            <label className="form-label"><strong>Job Number:</strong></label>
                                            {/* {" " + data.orderDetails.jobNumber} */}
                                            <input type="text"
                                                className="form-control"
                                                name="orderDetails.jobNumber"
                                                placeholder="Job Number"
                                                value={data.orderDetails.jobNumber}
                                                onChange={handleInputChange}

                                            />
                                        </div>
                                    </div>
                                )}
                                {data.shipToSequenceNumber && (
                                    <div className="container-extra-item">
                                        <div className="mb-2">
                                            <label className="form-label"><strong>Ship To Sequence Number:</strong></label>
                                            {/* {" " + data.shipToSequenceNumber} */}
                                            <input type="text"
                                                className="form-control"
                                                name="shipToSequenceNumber"
                                                placeholder="Ship To Sequence Number"
                                                value={data.shipToSequenceNumber}
                                                onChange={handleInputChange}

                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {data.notes && (
                                <div className="NoteInfo">
                                    <div>
                                        <b>{"Notes: "}</b>
                                    </div>
                                    <div className="TrackingInfo_content">
                                        {/* {data.notes}  */}
                                        <input type="text"
                                            className="form-control"
                                            name="notes"
                                            placeholder="Notes"
                                            value={data.notes}
                                            onChange={handleInputChange}

                                        />
                                    </div>
                                </div>
                            )}
                            <div className="billingDeatilsTable">
                                <div className="ShippingAddress">
                                    <div className="AddressText">Shipping Address</div>
                                    <div className="AddressText_items">
                                        {/* {data.shipTo.name} */}
                                        <input type="text" className="form-control mb-2" name="shipTo.name" placeholder="Ship To Name" value={data.shipTo.name} onChange={handleInputChange} />
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {data.shipTo.addressLine1 ? data.shipTo.addressLine1 : ""} */}
                                        <input type="text" className="form-control mb-2" name="shipTo.addressLine1" placeholder="Address Line 1" value={data.shipTo.addressLine1 ? data.shipTo.addressLine1 : ""} onChange={handleInputChange} />
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {data.shipTo.addressLine2 ? data.shipTo.addressLine2 : ""} */}
                                        <input type="text" className="form-control mb-2" name="shipTo.addressLine2" placeholder="Address Line 2" value={data.shipTo.addressLine2 ? data.shipTo.addressLine2 : ""} onChange={handleInputChange} />
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {data.shipTo.addressLine3 ? data.shipTo.addressLine3 : ""} */}
                                        <input type="text" className="form-control mb-2" name="shipTo.addressLine3" placeholder="Address Line 3" value={data.shipTo.addressLine3 ? data.shipTo.addressLine3 : ""} onChange={handleInputChange} />
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {data.shipTo.city} */}
                                        <input type="text" className="form-control mb-2" name="shipTo.city" placeholder="Ship To City" value={data.shipTo.city} onChange={handleInputChange} />
                                        {data.shipTo.state && " , "}
                                        <input type="text" className="form-control mb-2" name="shipTo.state" placeholder="Ship To State" value={data.shipTo.state} onChange={handleInputChange} />
                                        {/* {data.shipTo.state} */}
                                        <input type="text" className="form-control mb-2" name="shipTo.state" placeholder="Ship To State" value={data.shipTo.state} onChange={handleInputChange} />
                                        {data.shipTo.country && " , "}
                                        <input type="text" className="form-control mb-2" name="shipTo.country" placeholder="Ship To Country" value={data.shipTo.country} onChange={handleInputChange} />
                                        {/* {data.shipTo.country} */}
                                        {/* <input type="text" className="form-control" name="orderType" value={data.shipTo.country} onChange={handleInputChange} /> */}

                                    </div>
                                    <div className="AddressText_items">
                                        {/* {data.shipTo.zipCode}
                                    {data.shipTo.shipToPhone && " , "}
                                    {data.shipTo.shipToPhone} */}
                                        <input type="text" className="form-control mb-2" name="shipTo.zipCode" placeholder="Ship To Zip Code" value={data.shipTo.zipCode} onChange={handleInputChange} />
                                        <input type="text" className="form-control mb-2" name="shipTo.shipToPhone" placeholder="Ship To Phone" value={data.shipTo.shipToPhone} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="BillingAddress">
                                    <div hidden={false} className="AddressText">
                                        Billing Address
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {data.billTo.company} */}
                                        <input type="text" className="form-control mb-2" name="billTo.company" placeholder="Bill To Company" value={data.billTo.company} onChange={handleInputChange} /></div>
                                    <div className="AddressText_items">
                                        {/* {data.billTo.addressLine1} */}
                                        <input type="text" className="form-control mb-2" name="billTo.addressLine1" placeholder="Bill To Address Line 1" value={data.billTo.addressLine1} onChange={handleInputChange} /></div>

                                    <div className="AddressText_items">
                                        {/* {data.billTo.addressLine2} */}
                                        <input type="text" className="form-control mb-2" name="billTo.addressLine2" placeholder="Bill To Address Line 2" value={data.billTo.addressLine2} onChange={handleInputChange} /></div>
                                    <div className="AddressText_items">
                                        {/* {data.billTo.addressLine3} */}
                                        <input type="text" className="form-control mb-2" name="billTo.addressLine3" placeholder="Bill To Address Line 3" value={data.billTo.addressLine3} onChange={handleInputChange} /></div>
                                    <div className="AddressText_items">
                                        {/* {data.billTo.city} */}
                                        <input type="text" className="form-control mb-2" name="billTo.city" placeholder="Bill To City" value={data.billTo.city} onChange={handleInputChange} />
                                        {data.billTo.state && " , "}
                                        {/* {data.billTo.state} */}
                                        <input type="text" className="form-control mb-2" name="billTo.state" placeholder="Bill To State" value={data.billTo.state} onChange={handleInputChange} />
                                        {data.billTo.country && " , "}
                                        {/* {data.billTo.country} */}
                                        <input type="text" className="form-control mb-2" name="billTo.country" placeholder="Bill To Country" value={data.billTo.country} onChange={handleInputChange} />
                                        {data.billTo.zipCode && " , "}
                                        {/* {data.billTo.zipCode} */}
                                        <input type="text" className="form-control mb-2" name="billTo.zipCode" placeholder="Bill To ZipCode" value={data.billTo.zipCode} onChange={handleInputChange} />
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {data.customerContactInformation.customerContactPhone} */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactPhone" placeholder="Customer Contact Phone" value={data.customerContactInformation.customerContactPhone} onChange={handleInputChange} />
                                        {data.customerContactInformation.customerContactEmail && " , "}
                                        {/* {data.customerContactInformation.customerContactEmail} */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactEmail" placeholder="Customer Contact Email" value={data.customerContactInformation.customerContactEmail} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="CustomerAddress">
                                    <div hidden={false} className="AddressText">
                                        Customer Information
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {
                                        data.customerContactInformation.customerContactAddress
                                            .addressLine1
                                    } */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactAddress.addressLine1" placeholder="Address Line 1" value={data.customerContactInformation.customerContactAddress.addressLine1} onChange={handleInputChange} />
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {
                                        data.customerContactInformation.customerContactAddress
                                            .addressLine2
                                    } */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactAddress.addressLine2" placeholder="Address Line 2" value={data.customerContactInformation.customerContactAddress.addressLine2} onChange={handleInputChange} />
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {
                                        data.customerContactInformation.customerContactAddress
                                            .addressLine3
                                    } */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactAddress.addressLine3" placeholder="Address Line 3" value={data.customerContactInformation.customerContactAddress.addressLine3} onChange={handleInputChange} />
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {data.customerContactInformation.customerContactAddress.city} */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactAddress.city" placeholder="City" value={data.customerContactInformation.customerContactAddress.city} onChange={handleInputChange} />
                                        {data.customerContactInformation.customerContactAddress.state &&
                                            " , "}
                                        {/* {data.customerContactInformation.customerContactAddress.state} */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactAddress.state" placeholder="State" value={data.customerContactInformation.customerContactAddress.state} onChange={handleInputChange} />
                                        {data.customerContactInformation.customerContactAddress.country &&
                                            " , "}
                                        {/* {data.customerContactInformation.customerContactAddress.country} */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactAddress.country" placeholder="Country" value={data.customerContactInformation.customerContactAddress.country} onChange={handleInputChange} />
                                        {data.customerContactInformation.customerContactAddress.zipCode &&
                                            " , "}
                                        {/* {data.customerContactInformation.customerContactAddress.zipCode} */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactAddress.zipCode" placeholder="Zip Code" value={data.customerContactInformation.customerContactAddress.zipCode} onChange={handleInputChange} />
                                    </div>
                                    <div className="AddressText_items">
                                        {/* {data.customerContactInformation.customerContactPhone} */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactPhone" placeholder="Phone" value={data.customerContactInformation.customerContactPhone} onChange={handleInputChange} />
                                        {data.customerContactInformation.customerContactEmail && " , "}

                                        {/* {data.customerContactInformation.customerContactEmail} */}
                                        <input type="text" className="form-control mb-2" name="customerContactInformation.customerContactEmail" placeholder="Email" value={data.customerContactInformation.customerContactEmail} onChange={handleInputChange} />
                                    </div>
                                </div>
                                <div className="OrderSummerry">
                                    <div className="AddressText">Order Summary</div>
                                    <div>
                                        <span className="AddressText">Grand Total:{"  "}</span>
                                        <span className="AddressText">${itemSubTotal.toFixed(2)}</span>
                                    </div>

                                    {/* <tr>
                  <td>Item(s) Subtotal:</td>
                  <td>${itemSubTotal.toFixed(2)}</td>
                </tr> */}
                                    {/* <tr>
                  <td>Shipping:</td>
                  <td>$0</td>
                </tr> */}
                                    {/* <tr>
                  <td>Total:</td>
                  <td>${itemSubTotal}</td>
                </tr> */}
                                    {/* <tr>
                  <td>Promotion Applied:</td>
                  <td>$0</td>
                </tr> */}
                                </div>
                            </div>
                            <div style={{ width: "100%" }}>
                                <Accordion defaultExpanded className="order_content_accordion">
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div className="orderHistoryHeading">Order Contents</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className={"ordertableContainer"}>
                                            <table className="OrderLineTable">
                                                <tbody>
                                                    <tr className="">
                                                        <th style={{ width: "35%" }}>Product Name</th>
                                                        <th style={{ width: "35%" }}>Product Description</th>
                                                        <th style={{ width: "15%" }}>More Information</th>
                                                        <th style={{ width: "10%" }}>Quantity</th>
                                                        {data.orderLineItems.length > 1 ? <th style={{ width: "35%" }}>Delete</th> : (null)}
                                                    </tr>
                                                </tbody>
                                                <tbody>
                                                    {Array.isArray(data.orderLineItems) &&
                                                        data.orderLineItems.map((x: any, i: any) => (
                                                            <tr className="" key={i}>
                                                                <td>
                                                                    <div>
                                                                        <div className="orderLineProductName">
                                                                            {/* {x.productName} */}
                                                                            <input type="text" className="form-control" name={`orderLineItems[${i}].productName`} value={x.productName} placeholder="Product Name" onChange={handleInputChangeItemName} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="orderLinegroup">
                                                                        {/* <div>ASIN:{"NA"}</div> */}
                                                                        <div>
                                                                            SKU:{" "}
                                                                            <span className="orderLineProductName">
                                                                                {/* {OLT == "F" ?
                                                                             x.customerPartNumber : 
                                                                             x.agilityPartNumber} */}
                                                                                {OLT == "F" ?
                                                                                    <input type="text" className="form-control" name={`orderLineItems[${i}].customerPartNumber`} value={x.customerPartNumber} onChange={handleInputChange} /> :
                                                                                    <input type="text" className="form-control" name={`orderLineItems[${i}].agilityPartNumber`} value={x.agilityPartNumber} onChange={handleInputChange} />}

                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <div className="OrderLineItemdescription">
                                                                            {/* {x.productDescription} */}
                                                                            <input type="text" className="form-control" name={`orderLineItems[${i}].productDescription`} value={x.productDescription} placeholder="Product Description" onChange={handleInputChange} />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div
                                                                        onClick={(e) => handleClickEditMode(e, x, i)}
                                                                        className="attribute_icon"
                                                                    >
                                                                        <InfoIcon className="Icon_color_global" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <div className="OrderLineItemdescription">
                                                                            {/* {x.quantity} */}
                                                                            <input type="text" className="form-control" name={`orderLineItems[${i}].quantity`} value={x.quantity} onChange={handleInputChange} />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {data.orderLineItems.length > 1 ? <td><button className="btn btn-sm btn-danger" onClick={(e) => DeleteAlert(i)}><i className="bi bi-trash-fill"></i></button></td> : (null)}
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center",
                                                }}
                                            >
                                                <MyAttributes attributes={attributes} handleInputChanges={handleInputChange} indexValue={currentIndex} isEdit={isEditing} />
                                            </Popover>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            <div style={{ width: "100%" }}>
                                <div
                                    hidden={orderShipments.length === 0 ? true : false}
                                    className="orderHistoryHeading"
                                >
                                    Shipment Configuration
                                </div>
                                <div className="OrderHistoryTableConatiner">
                                    {Array.isArray(orderShipments) &&
                                        orderShipments.map((item: any, i: any) =>
                                            item.containers.map((child: any, childIndex: any) => (
                                                <table key={i + 100} className="OrderHistoryTable">
                                                    <tbody>
                                                        <tr
                                                            hidden={child.containerIdentifier ? false : true}
                                                            className="OdhistoryTableRow"
                                                        >
                                                            <th>
                                                                BOX:
                                                                {child.containerIdentifier}
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                    <tbody>
                                                        <tr
                                                            hidden={
                                                                child.height ||
                                                                    child.length ||
                                                                    child.width ||
                                                                    child.weight
                                                                    ? false
                                                                    : true
                                                            }
                                                        >
                                                            <td style={{ display: "flex", gap: "1rem" }}>
                                                                <div
                                                                    hidden={child.length ? false : true}
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: 2,
                                                                    }}
                                                                >
                                                                    <div style={{ fontSize: 14, fontWeight: 600 }}>
                                                                        LENGTH
                                                                    </div>
                                                                    <div style={{ fontSize: 14, fontWeight: 400 }}>
                                                                        {child.length}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    hidden={child.height ? false : true}
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: 2,
                                                                    }}
                                                                >
                                                                    <div style={{ fontSize: 14, fontWeight: 600 }}>
                                                                        HEIGHT
                                                                    </div>
                                                                    <div style={{ fontSize: 14, fontWeight: 400 }}>
                                                                        {child.height}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    hidden={child.width ? false : true}
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: 2,
                                                                    }}
                                                                >
                                                                    <div style={{ fontSize: 14, fontWeight: 600 }}>
                                                                        WIDTH
                                                                    </div>
                                                                    <div style={{ fontSize: 14, fontWeight: 400 }}>
                                                                        {child.width}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    hidden={child.weight ? false : true}
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        gap: 2,
                                                                    }}
                                                                >
                                                                    <div style={{ fontSize: 14, fontWeight: 600 }}>
                                                                        WEIGHT
                                                                    </div>
                                                                    <div style={{ fontSize: 14, fontWeight: 400 }}>
                                                                        {child.weight}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    {item.shipmentDetails.shippedDate && (
                                                                        <div>
                                                                            <span className="Form_field_label">
                                                                                Shipped Date
                                                                            </span>
                                                                            {": "}
                                                                            <span className="Form_field_info">
                                                                                {dateConverter(
                                                                                    item.shipmentDetails.shippedDate
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    {item.shipmentDetails.estimatedDeliveryDate && (
                                                                        <div>
                                                                            <span className="Form_field_label">
                                                                                Expected Shipment Delivery Date
                                                                            </span>
                                                                            {": "}
                                                                            <span className="Form_field_info">
                                                                                {dateConverter(
                                                                                    item.shipmentDetails.estimatedDeliveryDate
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    <div className="ItemLabel">
                                                                        {item.shipmentDetails.shipmentStatus
                                                                            ? item.shipmentDetails.shipmentStatus
                                                                            : ""}
                                                                    </div>
                                                                    {Array.isArray(child.packedItems) &&
                                                                        child.packedItems.map((pack: any, i: any) => (
                                                                            <div className="itemsDetailContainer">
                                                                                <div className="ItemImage">
                                                                                    <img
                                                                                        src={pack.imageUrl ? pack.imageUrl : Item}
                                                                                        alt="icon"
                                                                                    ></img>
                                                                                </div>
                                                                                <div className="ItemDetails">
                                                                                    <div className="ItemDetailsInfo">
                                                                                        <div className="ItemDetailsInfoname1">
                                                                                            {pack.productName}
                                                                                        </div>
                                                                                        <div className="ItemDetailDescription">
                                                                                            {pack.productDescription}
                                                                                        </div>

                                                                                        <div className="ItemDetailDescription2">
                                                                                            <div>
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 14,
                                                                                                        fontWeight: 600,
                                                                                                    }}
                                                                                                >
                                                                                                    UNIT:{" "}
                                                                                                </span>
                                                                                                <span>{pack.quantityShipped}</span>
                                                                                            </div>
                                                                                            {/* <div>
                                          <span
                                            style={{
                                              fontSize: 14,
                                              fontWeight: 600,
                                            }}
                                          >
                                            ASIN:
                                          </span>
                                          <span>{"NA"}</span>
                                        </div> */}
                                                                                            <div>
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 14,
                                                                                                        fontWeight: 600,
                                                                                                    }}
                                                                                                >
                                                                                                    SKU:{" "}
                                                                                                </span>
                                                                                                <span>{pack.aquashipPartNumber}</span>
                                                                                            </div>
                                                                                            <div>
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 14,
                                                                                                        fontWeight: 600,
                                                                                                    }}
                                                                                                >
                                                                                                    Price:{" "}
                                                                                                </span>
                                                                                                <span>${pack.salesPrice}</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="ItemDetailDescription2">
                                                                                            <div>
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 14,
                                                                                                        fontWeight: 600,
                                                                                                    }}
                                                                                                >
                                                                                                    Tracking Number:{" "}
                                                                                                </span>
                                                                                                <span>{child.trackingNumber}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="ItemActions">
                                                                                    {/* <Button
                                style={{ backgroundColor: "#0D6EFD" }}
                                fullWidth
                                variant="contained"
                              >
                                Contained
                              </Button>
                              <Button fullWidth variant="outlined">
                                Undoubtable
                              </Button>
                              <Button fullWidth variant="outlined">
                                Generators
                              </Button>
                              <Button fullWidth variant="outlined">
                                Undoubtable
                              </Button>
                              <Button fullWidth variant="outlined">
                                Predefined
                              </Button> */}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ))
                                        )}
                                </div>
                            </div>
                            <div style={{ width: "100%" }} ref={refHistory}>
                                <div className="orderHistoryHeading">Order History</div>
                                <div className="OrderHistoryTableConatiner">
                                    <table className="OrderHistoryTable">
                                        <tbody>
                                            <tr className="OdhistoryTableHeadRow">
                                                <th style={{ width: "30%" }}>Date</th>
                                                <th>System Interaction Status</th>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            {Array.isArray(orderHistory) &&
                                                orderHistory.map((x: any, i: any) => (
                                                    <tr className="OdhistoryTableRow" key={i}>
                                                        <td>{x.createdDate}</td>
                                                        <td>{x.comments}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>

                ) : (
                    <>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="heading-section">
                                    <h1 className="fc-h1">Order Details</h1>                                    
                                    <div className="tr-btns">
                                        <h3 className="od-s fc-h3 m-0">{findstatus(data.statusCode)}</h3>
                                        <a target="_blank" onClick={handleDownloadCSV} type="button" className="tr-btns-links me-3"><span><i className="bi bi-download"></i></span>Download</a>
                                        <a target="_blank" type="button" onClick={handleGoBack} className="btn btn-sm btn-primary"><span className="pe-1"><i className="bi bi-arrow-left"></i></span>Back</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-0">
                            <div className="col-sm-6 col-md-3">
                                <div className="od-o-nv">
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Ordered on:
                                        </div>
                                        <div className="o-v">
                                            {dateConverter(data.customerOrderDateTime)}
                                        </div>
                                    </div>
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Order #:
                                        </div>
                                        <div className="o-v">
                                            {data.customerOrderId}
                                        </div>
                                    </div>
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Shipping Method:
                                        </div>
                                        <div className="o-v">
                                            {data.orderDetails.shippingMethod}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="od-o-nv">
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Transaction Id:
                                        </div>
                                        <div className="o-v">
                                            {data.transactionId}
                                        </div>
                                    </div>
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Branch Code:
                                        </div>
                                        <div className="o-v">
                                            {data.branchCode}
                                        </div>
                                    </div>
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Customer Warehouse:
                                        </div>
                                        <div className="o-v">
                                            {data.customerWarehouseId}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="od-o-nv">
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Agility Order Id:
                                        </div>
                                        <div className="o-v">
                                            {data.agilityOrderId}
                                        </div>
                                    </div>
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Tracking #:
                                        </div>
                                        <div className="o-v">
                                            612877060958
                                        </div>
                                    </div>
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Agility Ship Via:
                                        </div>
                                        <div className="o-v">
                                            {data.orderDetails.agilityShipVia}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="od-o-nv br-n p-0">
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Customer Account Id:
                                        </div>
                                        <div className="o-v">
                                            {data.accountNumber}
                                        </div>
                                    </div>
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Source System:
                                        </div>
                                        <div className="o-v">
                                            {data.sourceSystem}
                                        </div>
                                    </div>
                                    <div className="o-nv-d">
                                        <div className="o-n">
                                            Ship To Sequence Number:
                                        </div>
                                        <div className="o-v">
                                            {data.shipToSequenceNumber}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="hr-style"></hr>
                        <div className="row g-0">
                            <div className="col-sm-6 col-md-3">
                                <div className="od-a-v ps-0">
                                    <div className="od-a-v-d">
                                        <div className="od-n">
                                            Shipping Address
                                        </div>
                                        <div className="od-v">
                                            <span>{data.shipTo.name}</span>
                                            <span>{data.shipTo.addressLine1 ? data.shipTo.addressLine1 : ""}</span>
                                            <span>{data.shipTo.addressLine2 ? data.shipTo.addressLine2 : ""}</span>
                                            <span>{data.shipTo.addressLine3 ? data.shipTo.addressLine3 : ""}</span>
                                            <span>{data.shipTo.city}
                                                {data.shipTo.state && " , "}
                                                {data.shipTo.state}
                                                {data.shipTo.country && " , "}
                                                {data.shipTo.country}</span>
                                            <span>{data.shipTo.zipCode}
                                                {data.shipTo.shipToPhone && " , "}
                                                {data.shipTo.shipToPhone}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="od-a-v">
                                    <div className="od-a-v-d">
                                        <div className="od-n">
                                            Billing Address
                                        </div>
                                        <div className="od-v">
                                            <span>{data.billTo.company}</span>
                                            <span>{data.billTo.addressLine1}</span>
                                            <span>{data.billTo.addressLine2}</span>
                                            <span>{data.billTo.city}
                                                {data.billTo.state && " , "}
                                                {data.billTo.state}
                                                {data.billTo.country && " , "}
                                                {data.billTo.country}
                                                {data.billTo.zipCode && " , "}
                                                {data.billTo.zipCode}</span>
                                            <span>{data.customerContactInformation.customerContactPhone}
                                                {data.customerContactInformation.customerContactEmail && " , "}
                                                {data.customerContactInformation.customerContactEmail}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="od-a-v">
                                    <div className="od-a-v-d">
                                        <div className="od-n">
                                            Customer Information
                                        </div>
                                        <div className="od-v">
                                            <span>{
                                                data.customerContactInformation.customerContactAddress
                                                    .addressLine1
                                            }</span>
                                            <span>{
                                                data.customerContactInformation.customerContactAddress
                                                    .addressLine2
                                            }</span>
                                            <span>{data.customerContactInformation.customerContactAddress.city}
                                                {data.customerContactInformation.customerContactAddress.state &&
                                                    " , "}
                                                {data.customerContactInformation.customerContactAddress.state}
                                                {data.customerContactInformation.customerContactAddress.country &&
                                                    " , "}
                                                {data.customerContactInformation.customerContactAddress.country}
                                                {data.customerContactInformation.customerContactAddress.zipCode &&
                                                    " , "}
                                                {data.customerContactInformation.customerContactAddress.zipCode}</span>
                                            <span>{data.customerContactInformation.customerContactPhone}
                                                {data.customerContactInformation.customerContactEmail && " , "}

                                                {data.customerContactInformation.customerContactEmail}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <div className="od-a-v br-n">
                                    <div className="od-a-v-d">
                                        <div className="od-n">
                                            Order Summary
                                        </div>
                                        <div className="od-v">
                                            <div className="od-v-t">
                                                Grand Total: ${itemSubTotal.toFixed(2)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="heading-section m-0">
                                    <h2 className="fc-h2">Order Content & Shipment Details</h2>
                                </div>
                                <table className="custom-table table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="c-th-1"><span className="th-title">Product Name</span></th>
                                            <th className="c-th-2"><span className="th-title">Product Description</span></th>
                                            <th className="c-th-3"><span className="th-title">Box #</span></th>
                                            <th className="c-th-4"><span className="th-title">Qty</span></th>
                                            <th className="c-th-5"><span className="th-title">Shipped Date</span></th>
                                            <th className="c-th-6"><span className="th-title">Tracking #</span></th>
                                            <th className="c-th-7"><span className="th-title">Price</span></th>
                                            <th className="c-th-8"><span className="th-title">Exp. Del. Date</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(data.orderLineItems) &&
                                            data.orderLineItems.map((x: any, i: any) => (
                                                <tr>
                                                    <td className="c-td-1">
                                                        <div className="t-c-d">
                                                            <div className="img">
                                                                <img src="/images/orderlisting-image.png" />
                                                            </div>
                                                            <div className="c-i">
                                                                <div className="content">
                                                                    {x.productName}
                                                                    <span>SKU: <strong>{OLT == "F" ? x.customerPartNumber : x.agilityPartNumber}</strong></span>
                                                                </div>
                                                                <div className="icon" onClick={(e) => handleClick(e, x)}>
                                                                    <span className="info-icon">
                                                                    <i className="bi bi-info-circle"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="c-td-2">
                                                        {x.productDescription}
                                                    </td>
                                                    
                                                    <td className="c-td-3">
                                                    {
                                                        Array.isArray(orderShipments) && orderShipments.length > 0 &&
                                                        orderShipments[i].containers[0].containerIdentifier
                                                    }
                                                    </td>
                                                    <td className="c-td-4">
                                                        {x.quantity}
                                                    </td>
                                                    <td className="c-td-5">

                                                        {
                                                            Array.isArray(orderShipments) && orderShipments.length > 0 &&
                                                            orderShipments[i].shipmentDetails.shippedDate
                                                        }
                                                    </td>
                                                    <td className="c-td-6">
                                                        {
                                                            Array.isArray(orderShipments) && orderShipments.length > 0 &&
                                                            orderShipments[i].containers[0].trackingNumber
                                                        }
                                                    </td>
                                                    <td className="c-td-7">
                                                        {
                                                            Array.isArray(orderShipments) && orderShipments.length > 0 &&
                                                            orderShipments[i].containers[0].packedItems[0].salesPrice
                                                        }
                                                    </td>
                                                    <td className="c-td-8">
                                                        {
                                                            Array.isArray(orderShipments) && orderShipments.length > 0 &&
                                                            orderShipments[i].shipmentDetails.estimatedDeliveryDate
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>

                                </table>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                >
                                    <MyAttributes attributes={attributes} handleInputChanges={handleInputChange} indexValue={currentIndex} isEdit={isEditing} />
                                </Popover>
                            </div>
                        </div>
                        {Array.isArray(orderHistory) && orderHistory.length > 0 &&
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="heading-section m-0">
                                        <h2 className="fc-h2">Order History</h2>
                                    </div>
                                    <table className="custom-table table table-bordered">
                                        <thead>
                                            <tr>
                                                <th><span className="th-title">Date</span></th>
                                                <th><span className="th-title">Logs</span></th>
                                                <th><span className="th-title">Modified User</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderHistory.map((x: any, i: any) => (
                                                <tr>
                                                    <td>
                                                        {x.createdDate}
                                                    </td>
                                                    <td>
                                                        {x.comments}
                                                    </td>
                                                    <td>
                                                        {""}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </>
                )}
            </div>
        </>
    );
}

export default OrderDetails;




